import { all } from 'redux-saga/effects';

//public
import loginSaga from './auth/saga';
import LayoutSaga from './layout/saga';

export default function* rootSaga() {
  yield all([
    //public
    loginSaga(),
    LayoutSaga(),
  ]);
}
